import { bffClient, IClient } from 'shared/client'

import {
  IOrganisationSetting,
  IOrganisationSettingPatch,
} from '../model/organisation-settings.model'

export class OrganisationSettingsApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getOrganisationSettings() {
    return this.client.get<IOrganisationSetting[]>('/admin/settings')
  }

  async updateOrganisationSettings(patch: IOrganisationSettingPatch) {
    return this.client.post<
      IOrganisationSetting,
      { setting: IOrganisationSettingPatch }
    >('/admin/settings', { setting: patch })
  }
}

export const organisationSettingsApi = new OrganisationSettingsApi(bffClient)
