export const organisationSettingsEn = {
  defaultCountryForm: {
    title: 'Country',
    description:
      'This will set the default value for all country related selection fields (e.g. address) in this account.',
    cta: {
      submit: 'Save country',
    },
  },
}
