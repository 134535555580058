import { CountryAlpha3Code } from 'entities/country'
import { OrganisationId } from 'entities/organisation'

export type OrganisationSettingId = number

export enum OrganisationSettingTypes {
  Country = 'country',
  // other types aren't used (e.g. manage_columns_transactions)
  ManageColumnsTransactions = 'manage_columns_transactions',
}

export interface IOrganisationSetting {
  id: OrganisationSettingId
  organizationId: OrganisationId
  value: CountryAlpha3Code | string
  name: OrganisationSettingTypes
}

export type IOrganisationSettingPatch = Pick<
  IOrganisationSetting,
  'name' | 'value'
>
